import React from 'react';

const IbookContentFour = () => {
  return (
    <>
      <section className='ptb-120 bg-light-yellow-ibook pb-80'>
        <div className='container'>
          <div className="row justify-content-center">
            <div className='col-md-7 text-center'>
              <h2 className='title2 pb-2'>Essential Features to Keep your Business Running
              </h2>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">

            <div className="col-lg-4 col-md-6">
              <div className="mb-4 mb-lg-0 ibook-timeline" >
                <div className="ibook-timeline-icon">
                  <i className="fa-light fa-box fa-2xl"></i>
                </div>
                <h5 className='  mb-3'>Inventory Tracking</h5>
                <p>
                  Real-time updates on inventory levels, including stock availability and locations.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ibook-timeline  mb-4 mb-lg-0">
                <div className="ibook-timeline-icon">
                  <i className="fa-sharp fa-light fa-barcode fa-2xl"></i>
                </div>
                <h5 className='  mb-3'>Barcode Integration</h5>
                <p>
                  Support for barcode scanning to ensure fast and accurate tracking of items.              </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ibook-timeline  mb-4 mb-lg-0">
                <div className="ibook-timeline-icon">
                  <i className="fa-sharp fa-light fa-tags fa-2xl"></i>
                </div>
                <h5 className='  mb-3'>Item Categorization</h5>
                <p>
                  Organize products into categories for easy identification and management.              </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ibook-timeline  mb-4 mb-lg-0">
                <div className="ibook-timeline-icon">
                  <i className="fa-sharp fa-light fa-file-invoice fa-2xl"></i>
                </div>
                <h5 className='  mb-3'>Requisition Management</h5>
                <p>
                  Enable the request, approval, and issuance of items from the store.              </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ibook-timeline  mb-4 mb-lg-0">
                <div className="ibook-timeline-icon">
                  <i className="fa-light fa-truck fa-2xl"></i>
                </div>
                <h5 className='  mb-3'>Supplier and Vendor Management</h5>
                <p>
                  Track vendor details, delivery times, and order history.              </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ibook-timeline  mb-4 mb-lg-0">
                <div className="ibook-timeline-icon">

                  <i className="fa-light fa-bell fa-2xl"></i>
                </div>
                <h5 className=' mb-3'>Stock Alerts</h5>
                <p>
                  Notifications for low stock or excess inventory, ensuring timely restocking.              </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ibook-timeline  mb-4 mb-lg-0">
                <div className="ibook-timeline-icon">

                  <i className="fa-light fa-clipboard-check fa-2xl"></i>
                </div>
                <h5 className=' mb-3'>Issuance Tracking</h5>
                <p>
                Keep records of all issued items, who they were issued to, and when.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ibook-timeline  mb-4 mb-lg-0">
                <div className="ibook-timeline-icon">

                  <i className="fa-light fa-file-invoice fa-2xl"></i>
                </div>
                <h5 className=' mb-3'>Purchase Orders</h5>
                <p>
                Create and manage purchase orders for replenishing stock.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ibook-timeline  mb-4 mb-lg-0">
                <div className="ibook-timeline-icon">

                  <i className="fa-light fa-file-alt fa-2xl"></i>
                </div>
                <h5 className=' mb-3'>Auditing and Reporting</h5>
                <p>
                Generate reports on stock movements, usage trends, and inventory valuations.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default IbookContentFour;