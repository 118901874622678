import React from 'react';
import SectionTitle from '../common/SectionTitle';

const IbookContentTwo = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
                <h2 className='title2'>Automated Restocking Alerts</h2>
            <p>The system provides automated alerts for low-stock or out-of-stock items, ensuring timely restocking to prevent shortages and maintain operational efficiency.
</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/ibook1.jpg'
                  alt='ibook'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IbookContentTwo;
