import React, { useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const IbookContentFive = () => {
  // Controls for Framer Motion animations
  const controls = useAnimation();

  // Hook to track if the section is in view
  const [ref, inView] = useInView({
    threshold: 0.9, // Trigger when 20% of the section is visible
    triggerOnce: true, // Only trigger animation once
  });

  // Start animation when the section is in view
  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  // Variants for the parent (ul) to stagger children
  const listContainerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Time between each child animation
      },
    },
  };

  // Variants for the child (li) items
  const listItemVariants = {
    hidden: { opacity: 0, y: 20 }, // Start below and invisible
    visible: { opacity: 1, y: 0 }, // Animate to visible and in place
  };

  return (
    <>
      <section
        ref={ref} // Attach the ref to track when this section enters the viewport
        className="why-choose-us ptb-120 pb-0"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-12">
              <div className="why-choose-content">
                <h2>
                  Why use <br /> Zentixs Ibook?
                </h2>
              </div>
            </div>
            <div className="col-lg-8 col-12">
              {/* Motion.ul with list container variants */}
              <motion.ul
                className="list-unstyled d-flex flex-wrap list-three-col mb-0"
                variants={listContainerVariants}
                initial="hidden"
                animate={controls} // Controlled animation
              >
                {/* Each li is a motion.li */}
                <motion.li
                  className="py-3 d-flex align-items-start"
                  variants={listItemVariants}
                >
                  <i className="fas fa-check-circle me-2 text-primary"></i>
                  <p>
                    <strong>User Access Control:</strong> Role-based permissions
                    to control who can add, update, or issue inventory.
                  </p>
                </motion.li>

                <motion.li
                  className="py-3 d-flex align-items-start"
                  variants={listItemVariants}
                >
                  <i className="fas fa-check-circle me-2 text-primary"></i>
                  <p>
                    <strong>Multi-location Management:</strong> Track inventory
                    across multiple storage locations or warehouses.
                  </p>
                </motion.li>

                <motion.li
                  className="py-3 d-flex align-items-start"
                  variants={listItemVariants}
                >
                  <i className="fas fa-check-circle me-2 text-primary"></i>
                  <p>
                    <strong>Customizable Alerts:</strong> Set up notifications
                    for low stock, upcoming expirations, delayed deliveries,
                    etc.
                  </p>
                </motion.li>

                <motion.li
                  className="py-3 d-flex align-items-start"
                  variants={listItemVariants}
                >
                  <i className="fas fa-check-circle me-2 text-primary"></i>
                  <p>
                    <strong>Expiry Date Tracking:</strong> Monitor expiry dates
                    of perishable items, sending alerts for upcoming
                    expirations.
                  </p>
                </motion.li>

                <motion.li
                  className="py-3 d-flex align-items-start"
                  variants={listItemVariants}
                >
                  <i className="fas fa-check-circle me-2 text-primary"></i>
                  <p>
                    <strong>Stock Adjustment:</strong> Log stock adjustments due
                    to damage, loss, or returns.
                  </p>
                </motion.li>

                <motion.li
                  className="py-3 d-flex align-items-start"
                  variants={listItemVariants}
                >
                  <i className="fas fa-check-circle me-2 text-primary"></i>
                  <p>
                    <strong>Audit Trails:</strong> Maintain a detailed history
                    of stock movements and user actions for auditing purposes.
                  </p>
                </motion.li>

                <motion.li
                  className="py-3 d-flex align-items-start mb-0 pb-0"
                  variants={listItemVariants}
                >
                  <i className="fas fa-check-circle me-2 text-primary"></i>
                  <p>
                    <strong>Inventory Reconciliation:</strong> Simplify periodic
                    stock reconciliation by comparing physical counts with
                    system records.
                  </p>
                </motion.li>
              </motion.ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IbookContentFive;
