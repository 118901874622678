import React from 'react';
import SectionTitle from '../common/SectionTitle';

const LimsContent = () => {
  return (
    <>
      <section className='feature-section-two ptb-120 pt-0'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
          <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/lims2.jpg'
                  alt='lims'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
                <h2 className='title2'>Electronic Lab Notebook (ELN) Integration</h2>
               <p className='mt-3 lead'>The integrated ELN allows seamless documentation and data capture for experiments. It enables real-time data sharing, collaborative note-taking, and links sample IDs, protocols, and workflows for streamlined record-keeping.
</p>               
   
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default LimsContent;
