import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactFormThree from '../components/contact/ContactFormThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import LimsContent from '../components/Product/LimsContent';
import CustomerBrand from '../components/customer/CustomerBrand';
import LimsContentTwo from '../components/Product/LimsContentTwo';
import LimsContentThree from '../components/Product/LimsContentThree';
import HeroLims from '../components/Product/HeroLims';
import LimsContentFour from '../components/Product/LimsContentFour';
import FaqLabs from '../components/faqs/FaqLabs';
import TrustedUsers from '../components/work-process/TrustedUsers';

const Lims = () => {
  return (
    <Layout>
      <PageMeta title='Zentixs Labs | Laboratory Information Management Software | Adrta' />
      <Navbar/>
      <HeroLims />
      <CustomerBrand  />
      <LimsContent/>
      <LimsContentTwo />
      <LimsContentThree />
      <LimsContentFour />
      <FaqLabs />
      <TrustedUsers
        title="Keep Your Business Ready for Action"       
        buttonText="Request a demo"
        backgroundClass="cta-section cta-section-ibook"
      /> 
      <FooterOne footerLight />
    </Layout>
  );
};

export default Lims;
