import React, { useState } from 'react';
import { motion } from 'framer-motion';


const QmsContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [animating, setAnimating] = useState(false);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

 // Framer Motion variants for unique animation
 const tabVariants = {
  hidden: { 
    opacity: 0, 
    scale: 0.9, 
    y: 50 
  },
  visible: { 
    opacity: 1, 
    scale: 1, 
    y: 0, 
    transition: { 
      duration: 0.5, 
      ease: "easeOut" 
    } 
  },
  exit: { 
    opacity: 0, 
    scale: 0.9, 
    y: -50, 
    transition: { 
      duration: 0.4, 
      ease: "easeIn" 
    } 
  },
};

  const scrollContainerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.3 } },
  };

  return (
    <>
      <section className='feature-section two-bg-dark-light-track ptb-60'>
        <div className='container'>
          <div className="row custom-vertical-tabs justify-content-between">
            {/* Tab Navigation */}
            <div className="col-lg-4 custom-mb-mobile">
              <h5 className='h3 text-white mb-5'>Quality Enchantment for Data, <br /> Compliance, and Collaboration</h5>
              <div className="tab-titles">
                <button className={`tab-button ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
                  <i className="far fa-bell"></i> Incident management
                </button>
                <button className={`tab-button ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
                  <i className="far fa-sync-alt"></i> Change control
                </button>
                <button className={`tab-button ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>
                  <i className="far fa-exclamation-circle"></i> Deviation
                </button>
                <button className={`tab-button ${activeTab === 3 ? 'active' : ''}`} onClick={() => handleTabClick(3)}>
                  <i className="far fa-clipboard-list"></i> CAPA
                </button>
                <button className={`tab-button ${activeTab === 4 ? 'active' : ''}`} onClick={() => handleTabClick(4)}>
                  <i className="far fa-exclamation-triangle"></i> Risk management
                </button>
                <button className={`tab-button ${activeTab === 5 ? 'active' : ''}`} onClick={() => handleTabClick(5)}>
                  <i className="far fa-arrow-alt-circle-down"></i> OOS, OOC and OOT
                </button>
                <button className={`tab-button ${activeTab === 6 ? 'active' : ''}`} onClick={() => handleTabClick(6)}>
                  <i class="fa-sharp fa-regular fa-down-left-and-up-right-to-center"></i> Market compliant
                </button>
              </div>
            </div>

            {/* Tab Content */}
            <div className="col-lg-6">
             <motion.div
          key={activeTab} // Ensure Framer Motion knows when the content changes
          variants={tabVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="tab-content"
        >
                {activeTab === 0 &&
                  <motion.section
                    className='scroll-container'
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={scrollContainerVariants}
                    whileHover={{ overflowY: 'scroll' }}
                  >
                    <div className='p-3'>
                      <h5 className='h3'>Incident management</h5>
                      <p>Incident management in labs and pharmaceutical settings is crucial for ensuring safety, compliance, and operational efficiency.</p>
                      <h5 className='h5 text-primary mb-4'>Features</h5>
                      <div className="position-relative">

                        <div className='mb-4'>
                          <h5 className='h6'>Categorization and Prioritization</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Incident Types: Classification of incidents (e.g., safety, equipment failure, compliance).</li>
                            <li>Priority Levels: Ability to assign urgency and impact levels to incidents.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Workflow Automation</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Approval Processes: Automated workflows for incident review and approval.</li>
                            <li>Task Assignment: Automatic assignment of tasks to relevant personnel based on incident type.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Investigation and Root Cause Analysis</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Utilize templates for investigation plans, assign roles and tasks to stakeholders, apply tools for identifying root causes systematically, implement the 5 Whys technique to delve deeper into issues, and use Fishbone Diagrams for visualizing potential causes.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Corrective and Preventive Actions (CAPA)</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Action Tracking: Tools for tracking the implementation of corrective and preventive actions.</li>
                            <li>Effectiveness Review: Mechanisms to review the effectiveness of actions taken.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Compliance and Regulatory Features</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Audit Trails: Detailed logs of incident management processes for compliance audits.</li>
                            <li>Regulatory Compliance Checklists: Built-in compliance checklists relevant to pharma regulations.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Data Analytics and Reporting</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Dashboards: Visual dashboards for monitoring incidents and trends over time.</li>
                            <li>Custom Reports: Ability to generate custom reports for different stakeholders.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Training and Awareness</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Training Modules: Integrated training resources related to incident management.</li>
                            <li>Training Modules: Integrated training resources related to incident management.</li>
                          </ul>
                        </div>

                        <h5 className='h6'>Integration Capabilities</h5>
                        <ul class="custom-bullet-list custom-bullet-track">
                          <li>LIMS Integration: Ability to integrate with Laboratory Information Management Systems (LIMS) for seamless data flow.</li>
                          <li>Other Systems: Integration with other enterprise systems (e.g., ERP, EHR).</li>
                        </ul>


                      </div>
                    </div>
                  </motion.section>}

                {activeTab === 1 &&
                  <motion.section
                    className='scroll-container'
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={scrollContainerVariants}
                    whileHover={{ overflowY: 'scroll' }}
                  >
                    <div className='p-3'>


                      <h5 className='h3'>Change control</h5>
                      <p>Change control is a critical component of quality management systems in the pharmaceutical industry. It ensures that any changes to processes, equipment, materials, or systems are evaluated, documented, and controlled to maintain product quality and compliance with regulatory requirements.</p>
                      <h5 className='h5 text-primary mb-4'>Features</h5>
                      <div className="position-relative">

                        <div className='mb-4'>
                          <h5 className='h6'>Change Request Submission</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Standardized Forms: Use of standardized forms for submitting change requests to ensure consistency and completeness of information.</li>
                            <li>Identification of Change Types: Categories for changes (e.g., minor, major, emergency) to streamline the review process.</li>
                            <li>Generate the QC Number: Upon initial approval, a unique Quality Control (QC) number is generated for tracking purposes throughout the change control process.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Impact Assessment</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Risk Assessment: Evaluation of potential impacts on product quality, safety, efficacy, and compliance with regulatory standards.</li>
                            <li>Stakeholder Involvement: Inclusion of relevant stakeholders (e.g., quality assurance, production, regulatory affairs) in the assessment process.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Documentation and Approval Process</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Review and Approval Workflow:  Clearly defined workflows for reviewing and approving changes, including timelines and responsible parties.</li>
                            <li>Version Control:Maintaining version history of documents to track changes and ensure access to the most current information.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Implementation Planning</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Implementation Procedures:  Detailed procedures for implementing approved changes, including timelines, resources needed, and training requirements.</li>
                            <li>Communication Plan: Clear communication of changes to all affected personnel to ensure understanding and compliance.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Monitoring and Verification</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Post-Implementation Review:  Assessment of the effectiveness of the change after implementation to verify that it has achieved its intended goals.</li>
                            <li>Quality Metrics: Monitoring of key performance indicators (KPIs) to evaluate the impact of changes on product quality and process performance.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Training and Awareness</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Training Programs:  Development of training materials and programs to ensure that staff are aware of changes and understand their implications.</li>
                            <li>Documentation Access:  Easy access to documentation related to change control for training and reference purposes.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Historical Analysis and Trend Monitoring</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Change History Log:  Maintenance of a comprehensive log of all changes made, including details such as date, type, and reason for change.</li>
                            <li>Trend Analysis:  Analysis of change data over time to identify recurring issues or opportunities for improvement.</li>
                          </ul>
                        </div>


                        <h5 className='h6'>Regulatory Compliance</h5>
                        <ul class="custom-bullet-list custom-bullet-track">
                          <li>Alignment with Regulations:  Ensuring that the change control process complies with relevant regulatory requirements (e.g., FDA, EMA) and industry standards (e.g., ICH, ISO).</li>
                          <li>Audit Trails:  Maintaining comprehensive records of all change control activities for regulatory inspections and internal audits.</li>
                        </ul>

                      </div>
                    </div>
                  </motion.section>}


                {activeTab === 2 &&
                  <motion.section
                    className='scroll-container'
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={scrollContainerVariants}
                    whileHover={{ overflowY: 'scroll' }}
                  >
                    <div className='p-3'>
                      <h5 className='h3'>Deviation</h5>
                      <p>A Quality Management System (QMS) deviation typically includes features that allow organizations to track, investigate, and resolve deviations from standard operating procedures (SOPs), regulatory guidelines, or internal processes. </p>
                      <h5 className='h5 text-primary mb-4'>Features</h5>
                      <div className="position-relative">

                        <div className='mb-4'>
                          <h5 className='h6'>Classification of Deviations</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Critical, Major, Minor Categories: Categorization based on the severity of the deviation (e.g., minor, major, critical) to prioritize the response.</li>
                            <li>Root Cause Identification: Tools for identifying the root cause of the deviation, including dropdown menus or linked root cause analysis tools.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Workflow Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Deviation Approval Process: Defined workflow for routing deviations for review, approval, and closure.</li>
                            <li>Escalation Protocols: Automated escalation when deviations exceed defined thresholds (e.g., unresolved for a certain time).</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>CAPA Integration</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Corrective and Preventive Actions (CAPA): Link deviations to CAPA processes to ensure systematic investigation and resolution.</li>
                            <li>Action Plan Tracking: Monitoring and tracking of action plans tied to the deviation, with deadlines and task assignments.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Audit Trails and Traceability</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Version Control and History: Audit trails that track all changes and updates made to the deviation report.</li>
                            <li>Regulatory Compliance: Built-in tools to ensure compliance with regulations like FDA 21 CFR Part 11, EU GMP, ISO standards, etc.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Risk Assessment and FMEA Integration</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Risk Evaluation: Assess the risk impact of the deviation on product quality or patient safety.</li>
                            <li>FMEA (Failure Modes and Effects Analysis): Integration with FMEA to assess and mitigate risks associated with recurring or systemic deviations.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Real-Time Dashboards and Reporting</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Visual Dashboards: Real-time status of all open and closed deviations, with KPIs for timely resolution.</li>
                            <li>Analytics and Trend Analysis: Reporting tools for identifying deviation trends across processes or time periods.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Notification and Alerts</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Automated Notifications: Email or system alerts when deviations are assigned, escalated, or nearing deadlines.</li>
                            <li>SLA Management: Alerts tied to Service Level Agreements (SLAs) for deviation closure.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Cross-Functional Collaboration</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Task Assignment and Collaboration: Ability to assign tasks to different team members across departments (e.g., QA, production, R&D) for cross-functional input.</li>
                            <li>Commenting and Documentation Upload: Space for collaboration through comments and attaching supporting documents.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Compliance and Audits</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Audit-Ready Documentation: Comprehensive deviation records stored in a format ready for audits by internal and external parties (regulators, clients).</li>
                            <li>Regulatory Reporting: Automated generation of reports for regulatory submission.</li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </motion.section>}

                {activeTab === 3 &&
                  <motion.section
                    className='scroll-container'
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={scrollContainerVariants}
                    whileHover={{ overflowY: 'scroll' }}
                  >
                    <div className='p-3'>
                      <h5 className='h3'>CAPA</h5>
                      <p>A CAPA (Corrective and Preventive Action) software is designed to streamline the identification, investigation, and resolution of quality issues while ensuring compliance with regulatory standards.</p>
                      <h5 className='h5 text-primary mb-4'>Features</h5>
                      <div className="position-relative">

                        <div className='mb-4'>
                          <h5 className='h6'>CAPA Initiation and Reporting</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Issue Detection and Reporting: Allows users to log CAPA cases for various triggers such as deviations, audits, customer complaints, non-conformances, or incidents.</li>
                            <li>Customizable Forms: Predefined and customizable templates for entering CAPA details, such as the type of issue, severity, and affected departments.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Root Cause Analysis (RCA)</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Root Cause Investigation Tools: Built-in tools to perform root cause analysis, such as Fishbone (Ishikawa) diagrams, 5 Whys, or Fault Tree Analysis (FTA).</li>
                            <li>Linking to Issues: Ability to link the identified root cause to specific issues, deviations, or non-conformances within the system.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Corrective and Preventive Action Planning</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Action Plan Creation: Generate detailed corrective and preventive action plans to address root causes, prevent recurrence, and ensure compliance.</li>
                            <li>Task Assignment and Tracking: Assign CAPA tasks to relevant team members, with tracking mechanisms for due dates, task completion, and status updates.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Workflow Automation and Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Automated Workflows: Predefined or customizable workflows to guide the CAPA process from initiation to closure.</li>
                            <li>Escalation Procedures: Automated escalations based on defined triggers such as missed deadlines or unresolved critical issues.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Risk Assessment and FMEA Integration</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Risk Evaluation Tools: Built-in risk assessment features to evaluate the impact of identified issues and CAPA effectiveness.</li>
                            <li>FMEA (Failure Modes and Effects Analysis): Integration with FMEA tools to assess risks associated with the identified issues and preventive measures..</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>CAPA Effectiveness Verification</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Verification of Effectiveness (VoE): Tools for verifying the effectiveness of implemented corrective and preventive actions through follow-up assessments or monitoring data.</li>
                            <li>Continuous Monitoring: Mechanisms to track whether similar issues reoccur, ensuring the CAPA is effective in preventing recurrence.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Regulatory Compliance and Audit Trails</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Audit-Ready Documentation: Automatically generate and store audit-ready CAPA records that meet regulatory requirements such as FDA 21 CFR Part 11, ISO 9001, and ISO 13485.</li>
                            <li>Audit Trails: Complete history of all changes, actions, and decisions made throughout the CAPA process, ensuring transparency and traceability.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Cross-Functional Collaboration</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Team Collaboration: Collaboration features allowing cross-departmental teams (e.g., QA, R&D, operations) to work on CAPA resolutions, with commenting and document-sharing functionality.</li>
                            <li>Notifications and Alerts: Automated notifications and reminders to team members when actions are assigned, escalated, or nearing deadlines.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>CAPA Reporting and Analytics</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Customizable Reports: Generate reports on CAPA performance, including open cases, closure rates, trends, and recurring issues.</li>
                            <li>Dashboard Visualization: Real-time dashboards with visual analytics for tracking CAPA status, key metrics, and areas for improvement.</li>
                            <li>Trend Analysis: Identify patterns and trends in non-conformances, deviations, or other issues to proactively address underlying problems.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Document Control and Attachment</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Documentation Upload: Attach supporting documents such as SOPs, test reports, risk assessments, or audit findings to CAPA cases.</li>
                            <li>Document Version Control: Ensure that all attached documentation is version-controlled and aligned with QMS document management protocols.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Integration with Other QMS Modules</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Link to Deviations, Audits, and NCRs: CAPA software can be integrated with other QMS modules like deviation management, non-conformance reporting (NCR), and audit management for seamless workflows.</li>
                            <li>Compliance with Industry Regulations: Ensure that CAPA processes align with regulatory standards relevant to the industry, such as ISO, FDA, GMP, and ICH Q10.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Training Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Training Integration: Link CAPA actions to training requirements, ensuring that employees receive relevant training when corrective actions are implemented.</li>
                            <li>Automated Training Assignment: Assign new or revised training to employees after implementing CAPA actions to prevent recurrence.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Mobile and Cloud Access</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Mobile Accessibility: Access CAPA workflows and reports via mobile devices, enabling on-the-go management and updates.</li>
                            <li>Cloud-Based Functionality: Cloud-hosted solutions for real-time collaboration and data access from multiple locations or devices.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Data Security and Compliance</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Access Controls and Permissions: Role-based access to ensure only authorized personnel can view, edit, or approve CAPA actions.</li>
                            <li>Electronic Signatures: Compliant with regulatory requirements (e.g., FDA Part 11) for secure approvals through electronic signatures.</li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </motion.section>}

                {activeTab === 4 &&
                  <motion.section
                    className='scroll-container'
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={scrollContainerVariants}
                    whileHover={{ overflowY: 'scroll' }}
                  >
                    <div className='p-3'>
                      <h5 className='h3'>Risk management</h5>
                      <p>Risk management tool widely used in regulated environments like pharmaceuticals, manufacturing, and healthcare. It helps identify potential failure modes within a process, system, or design, evaluate the impact of those failures, and determine actions to mitigate risks. Zentixs Track software provides for risk management, aligned with FMEA (Failure Modes and Effects Analysis)</p>
                      <h5 className='h5 text-primary mb-4'>Features</h5>
                      <div className="position-relative">

                        <div className='mb-4'>
                          <h5 className='h6'>Define the Scope and System/Process</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Users can define the process, system, or product they want to analyze within the software.</li>
                            <li>Zentixs Track allows setting boundaries and constraints to clarify the scope of the analysis.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Assemble the FMEA Team</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>The software enables team collaboration, allowing various stakeholders with expertise to join the risk assessment process.</li>
                            <li>Roles and responsibilities can be assigned within the platform for effective participation.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Identify Potential Failure Modes</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Zentixs Track provides tools for brainstorming and identifying potential failure modes for different parts of the system or process.</li>
                            <li>It supports documentation and categorization of these failure modes for better tracking.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Identify Effects of Each Failure Mode</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>The software allows users to analyze the potential effects of each identified failure mode on the system, product, or user.</li>
                            <li>This step includes capturing both operational impacts and regulatory or safety concerns.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Determine the Cause of Each Failure Mode</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Users can document and link the root causes of each failure mode.</li>
                            <li>The software supports analysis of multiple causes, providing insight into why failures might occur.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Assign Severity, Occurrence, and Detection Ratings</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Zentixs Track has an integrated rating system where users can assign:
                              <dl className='mt-3'>
                                <dd>- Severity (S): Rate the seriousness of the failure's impact.</dd>
                                <dd>- Occurrence (O): Rate the likelihood of the failure happening.</dd>
                                <dd>- Detection (D): Rate the ability to detect the failure before it impacts the system or users.</dd>
                              </dl>
                            </li>
                            <li>These ratings help quantify risk for each failure mode.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Calculate the Risk Priority Number (RPN)</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>The software automatically calculates the Risk Priority Number (RPN) by multiplying the severity, occurrence, and detection ratings: RPN = S × O × D</li>
                            <li>This helps prioritize the most critical risks.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Prioritize Failure Modes</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Zentixs Track ranks the failure modes based on their RPN values.</li>
                            <li>Higher RPN values indicate higher risk, enabling teams to focus on the most pressing failure modes first.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Develop and Implement Action Plans</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>The software allows users to create action plans to mitigate identified risks.</li>
                            <li>It supports assigning tasks, setting deadlines, and tracking progress on the mitigation efforts.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Document Control and Attachment</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Documentation Upload: Attach supporting documents such as SOPs, test reports, risk assessments, or audit findings to CAPA cases.</li>
                            <li>Document Version Control: Ensure that all attached documentation is version-controlled and aligned with QMS document management protocols.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Integration with Other QMS Modules</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Link to Deviations, Audits, and NCRs: CAPA software can be integrated with other QMS modules like deviation management, non-conformance reporting (NCR), and audit management for seamless workflows.</li>
                            <li>Compliance with Industry Regulations: Ensure that CAPA processes align with regulatory standards relevant to the industry, such as ISO, FDA, GMP, and ICH Q10.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Training Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Training Integration: Link CAPA actions to training requirements, ensuring that employees receive relevant training when corrective actions are implemented.</li>
                            <li>Automated Training Assignment: Assign new or revised training to employees after implementing CAPA actions to prevent recurrence.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Mobile and Cloud Access</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Mobile Accessibility: Access CAPA workflows and reports via mobile devices, enabling on-the-go management and updates.</li>
                            <li>Cloud-Based Functionality: Cloud-hosted solutions for real-time collaboration and data access from multiple locations or devices.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Data Security and Compliance</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Access Controls and Permissions: Role-based access to ensure only authorized personnel can view, edit, or approve CAPA actions.</li>
                            <li>Electronic Signatures: Compliant with regulatory requirements (e.g., FDA Part 11) for secure approvals through electronic signatures.</li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </motion.section>}

                {activeTab === 5 &&
                  <motion.section
                    className='scroll-container'
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={scrollContainerVariants}
                    whileHover={{ overflowY: 'scroll' }}
                  >
                    <div className='p-3'>
                      <h5 className='h3'>OOS, OOC and OOT</h5>
                      <p>Out-of-Specification (OOS), Out-of-Trend (OOT), and Out-of-Control (OOC) events are critical aspects of quality management in regulated industries like pharmaceuticals and biotech. A QMS (Quality Management System) designed to handle these events would include specialized features to monitor, document, investigate, and resolve these issues.</p>
                      <h5 className='h5 text-primary mb-4'>Features</h5>
                      <div className="position-relative">

                        <div className='mb-4'>
                          <h5 className='h6'>Event Detection and Reporting</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Automated Data Capture: Automatically capture data from lab systems (LIMS), production equipment, or manufacturing systems to detect OOS, OOT, or OOC events in real-time.</li>
                            <li>Customizable Reporting Templates: Pre-defined templates for reporting OOS, OOT, or OOC events with fields such as test results, specifications, and control limits.</li>
                            <li>Immediate Notifications: Automated alerts and notifications to relevant personnel when an OOS, OOT, or OOC condition is detected.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Classification and Categorization</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>OOS, OOT, OOC Differentiation: Tools for differentiating between OOS (test results outside established specifications), OOT (data outside expected trend over time), and OOC (process or control charts showing deviation beyond control limits).</li>
                            <li>Severity and Risk Classification: Categorize events based on their severity (critical, major, minor) and potential impact on product quality or patient safety.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Investigation and Root Cause Analysis (RCA)</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Preliminary Investigation: Initiate a preliminary investigation into OOS, OOT, or OOC events to determine if retesting or further analysis is required.</li>
                            <li>Root Cause Analysis Tools: Integrated RCA tools such as 5 Whys, Fishbone Diagrams, and Fault Tree Analysis to identify the root causes of the event.</li>
                            <li>Deeper Investigations: In cases of confirmed OOS, OOT, or OOC events, support for conducting thorough investigations including data review, retesting protocols, and operator interviews.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Deviation and CAPA Integration</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Link to Deviation Management: If an OOS, OOT, or OOC event is confirmed, automatic linking to the deviation management process to track the issue.</li>
                            <li>Corrective and Preventive Actions (CAPA): Trigger CAPA processes based on investigation findings to address root causes and prevent recurrence.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Statistical Analysis and Trending</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Statistical Process Control (SPC): Tools to monitor process stability using control charts and alerting when processes go out of control (OOC).</li>
                            <li>Trend Analysis: Trend monitoring for OOT events, analyzing data over time to identify shifts or drifts in processes or results before they turn into OOS events.</li>
                            <li>Real-Time Data Analytics: Dashboard views of current and historical performance, with the ability to detect trends or deviations early in the process.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Workflow and Task Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Automated Workflows: Predefined workflows to guide users through the OOS, OOT, or OOC investigation, review, and approval process.
                            </li>
                            <li>Task Assignment: Assign tasks to specific team members (e.g., lab analysts, QA, production staff) for investigation, retesting, and approval steps.</li>
                            <li>Deadline Management: Set deadlines for investigation and resolution, with automatic escalation if timelines are not met.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Documentation and Record Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Document Control: Centralized document repository for storing all records related to OOS, OOT, or OOC investigations, including test results, investigation reports, and final conclusions.</li>
                            <li>Electronic Signatures: Compliant with FDA 21 CFR Part 11 for secure electronic signatures, ensuring audit readiness and regulatory compliance.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Regulatory Compliance and Audit Readiness</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Regulatory Templates: Ensure reports and investigations meet regulatory standards such as FDA, EMA, or ICH guidelines (e.g., ICH Q7 for OOS events).</li>
                            <li>Audit Trails: Full audit trails capturing every action taken during the event lifecycle, ensuring traceability and accountability.</li>
                            <li>Validation Support: Tools for validation of lab equipment or production processes to demonstrate that OOS, OOT, or OOC events are properly managed.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Risk Assessment and FMEA Integration</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Risk Evaluation: Risk assessment tools to evaluate the impact of the event on product quality and patient safety.</li>
                            <li>Failure Modes and Effects Analysis (FMEA): Integration with FMEA to identify potential failures and mitigate risks for processes showing OOS, OOT, or OOC trends.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Training and Competency Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Training Requirements: Automatically trigger training for personnel when OOS, OOT, or OOC events occur to ensure staff are up-to-date on procedures and corrective actions.</li>
                            <li>Competency Tracking: Track employee competency and certifications related to the testing and review processes associated with OOS/OOT/OOC events.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Cross-Departmental Collaboration</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Team Collaboration Tools: Built-in features for cross-departmental collaboration between QC, QA, manufacturing, and R&D to investigate and resolve OOS/OOT/OOC events.</li>
                            <li>Commenting and Document Sharing: Enable teams to collaborate by sharing documents, attaching evidence, and leaving comments on specific OOS/OOT/OOC cases.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Integration with Other Systems</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>LIMS and ERP Integration: Seamless integration with Laboratory Information Management Systems (LIMS), Enterprise Resource Planning (ERP), and Manufacturing Execution Systems (MES) to streamline data flow.</li>
                            <li>Equipment Data Integration: Directly pull data from production or laboratory equipment for automated analysis and detection of OOS/OOT/OOC events.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Customizable Reporting and Dashboards</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Real-Time Dashboards: Visual dashboards showing key metrics such as number of OOS, OOT, and OOC events, investigation status, and root cause distribution.</li>
                            <li>Custom Reports: Generate custom reports for trend analysis, regulatory submission, or internal audits, focusing on OOS/OOT/OOC events and resolutions.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Data Security and Compliance</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Access Controls and Permissions: Role-based access to ensure only authorized personnel can view, edit, or approve CAPA actions.</li>
                            <li>Electronic Signatures: Compliant with regulatory requirements (e.g., FDA Part 11) for secure approvals through electronic signatures.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </motion.section>}

                {activeTab === 6 &&
                  <motion.section
                    className='scroll-container'
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={scrollContainerVariants}
                    whileHover={{ overflowY: 'scroll' }}
                  >
                    <div className='p-3'>
                      <h5 className='h3'>Market compliant</h5>
                      <p> A Quality Management System (QMS) designed to manage market complaints focuses on ensuring that customer complaints related to product quality, safety, and performance are captured, investigated, resolved, and reported in a manner that meets regulatory requirements.  </p>
                      <h5 className='h5 text-primary mb-4'>Features</h5>
                      <div className="position-relative">

                        <div className='mb-4'>
                          <h5 className='h6'>Complaint Intake and Logging</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Multi-Channel Complaint Capture: Ability to log complaints from multiple sources such as email, phone, website, or social media.</li>
                            <li>Customizable Complaint Forms: Predefined and customizable forms for logging complaints with fields such as product details, batch/lot number, complaint category, and customer information.</li>
                            <li>Automated Complaint Number Generation: Unique identification numbers automatically assigned to each complaint for easy tracking.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Complaint Categorization and Classification</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Complaint Type Classification: Classify complaints by type (e.g., quality defect, safety issue, packaging problem, delivery delay) and severity (e.g., critical, major, minor).</li>
                            <li>Product and Batch Identification: Capture product-specific information such as SKU, batch/lot numbers, and manufacturing date for traceability.</li>
                            <li>Risk-Based Categorization: Assign risk levels to complaints based on potential impact on product quality, safety, or patient health.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Workflow and Task Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Automated Workflow: Predefined workflows to guide users through the complaint handling process, from intake to resolution, with automated task assignments.</li>
                            <li>Task Assignment and Tracking: Assign tasks to relevant teams (e.g., quality assurance, manufacturing, regulatory) for investigation, root cause analysis, and corrective actions.</li>
                            <li>Escalation Management: Escalate critical complaints that require immediate attention or investigation if deadlines are missed or certain thresholds are exceeded.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Root Cause Analysis (RCA)</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Investigation Tools: Built-in tools to perform root cause analysis, such as 5 Whys, Fishbone Diagrams, and Pareto Analysis, to identify the underlying cause of the complaint.</li>
                            <li>Link to Product or Process Deviations: Automatically link the market complaint to related deviations, non-conformances, or internal investigations, enabling comprehensive RCA.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Corrective and Preventive Actions (CAPA)</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>CAPA Integration: Link market complaints to CAPA processes to ensure corrective actions are taken to resolve the issue and preventive measures are put in place to avoid recurrence.</li>
                            <li>Action Plan Management: Create and track action plans tied to complaints, with clear timelines and task assignments to relevant teams.</li>
                          </ul>
                        </div>


                        <div className='mb-4'>
                          <h5 className='h6'>Regulatory Compliance and Reporting</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Regulatory Reporting: Automatically generate reports for regulatory authorities, such as FDA, EMA, or MHRA, based on complaint severity and regulatory requirements (e.g., FDA 21 CFR Part 820, ISO 13485).
                            </li>
                            <li>Audit Trails: Maintain comprehensive audit trails of all activities related to complaint handling, including who logged the complaint, investigation steps, actions taken, and approvals.</li>
                            <li>Electronic Signatures: Compliant with FDA 21 CFR Part 11 for electronic signatures to ensure secure and compliant approval workflows.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Customer Communication and Feedback</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Automated Notifications: Send automated email or SMS updates to customers about the status of their complaint, from receipt to resolution.</li>
                            <li>Customer Feedback Loop: Capture customer feedback post-resolution to assess satisfaction with the complaint handling process and identify areas for improvement.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Data Analytics and Reporting</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Customizable Dashboards: Real-time dashboards displaying key metrics, such as the number of complaints, resolution time, root cause trends, and complaint severity distribution.</li>
                            <li>Trend Analysis: Analyze complaint data over time to identify recurring issues, product trends, or potential areas of concern.</li>
                            <li>Regulatory and Management Reporting: Generate detailed reports for internal and external stakeholders, including regulatory authorities, senior management, and quality assurance teams.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Product Recall Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Recall Initiation: Trigger product recalls if market complaints indicate widespread quality or safety issues. Link complaints directly to recall processes for seamless coordination.</li>
                            <li>Recall Tracking: Track the status of product recalls, including product retrieval, communication with regulatory bodies, and customer notifications.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Integration with Other QMS Modules</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Integration with CAPA, Deviations, and Non-Conformance: Seamless integration with other QMS modules such as CAPA, deviation management, and non-conformance reporting, ensuring that complaints are thoroughly investigated and linked to broader quality issues.</li>
                            <li>ERP and CRM Integration: Integration with Enterprise Resource Planning (ERP) and Customer Relationship Management (CRM) systems to pull in product, customer, and order information for faster complaint processing.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>11. Document Management and Attachments</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Document Upload: Attach supporting documentation such as photos, test results, inspection reports, and customer correspondence to the complaint record.</li>
                            <li>Version Control: Ensure version control of any documents related to complaint handling, ensuring consistency and compliance with document control policies.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Mobile and Cloud Accessibility</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Mobile Complaint Logging: Allow employees to log and track complaints via mobile devices, improving response times for field teams or remote workers.</li>
                            <li>•	Cloud-Based Access: Cloud-hosted solutions for real-time collaboration, allowing global teams to access complaint data and take action from multiple locations.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Risk Management and FMEA Integration</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Risk Assessment: Tools for assessing the risk of a complaint to product safety and compliance, helping prioritize investigation efforts based on potential impact.</li>
                            <li>FMEA (Failure Modes and Effects Analysis): Integrate with FMEA to analyze risks associated with recurring complaints and implement measures to mitigate future risks.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Training and Competency Management</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Training Assignments: Link market complaints to employee training needs, ensuring that staff receive updated training in response to quality or safety concerns.</li>
                            <li>Training Tracking: Monitor the completion of training programs related to complaint resolution, product quality, and customer service.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Global Compliance and Multi-Language Support</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Multi-Language Interface: Support for multiple languages, allowing international teams to log and manage complaints in their local language.</li>
                            <li>Global Regulatory Compliance: Ensure compliance with country-specific regulations, including EU, US, and other regional standards for complaint handling and reporting.</li>
                          </ul>
                        </div>

                        <div className='mb-4'>
                          <h5 className='h6'>Continuous Improvement and Lessons Learned</h5>
                          <ul class="custom-bullet-list custom-bullet-track">
                            <li>Lessons Learned Database: Store outcomes and learnings from market complaints to build a knowledge base for continuous improvement and prevent recurrence of similar issues.</li>
                            <li>Continuous Improvement Tools: Tools for identifying improvement opportunities based on complaint trends, root causes, and product performance feedback.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </motion.section>}

                  </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QmsContent;
