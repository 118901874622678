import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqLabs = () => {
  return (
    <>
      <section className='faq-section ptb-120 pt-0'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 col-12'>
              <SectionTitle title='Frequently Asked Questions' centerAlign />

            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-6 custom-mb-mobile'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='false'
                    >
                      What is Zentixs Labs?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      <p>Zentixs Labs is an advanced Laboratory Information Management System (LIMS) designed to streamline and automate laboratory operations, enhance data management, and ensure regulatory compliance. It includes features for sample management, Electronic Lab Notebook (ELN) integration, inventory tracking, scientific data management, project management, and comprehensive reporting. </p>
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      How does Zentixs Labs handle sample management? 
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                   <p>Zentixs Labs provides end-to-end sample tracking, including barcode integration, location tracking, and chain-of-custody records. It supports customizable workflows, multi-stage approvals, sample pooling, aliquoting, and audit trails to maintain traceability and streamline sample processing. </p>
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      Can Zentixs Labs integrate with laboratory instruments? 
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Yes, Zentixs Labs offers instrument integration to automate data capture, reduce manual errors, and maintain a comprehensive instrument usage log. It supports calibration and maintenance scheduling, predictive maintenance, and generates audit trails for regulatory compliance.                     </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                    What features does the Electronic Lab Notebook (ELN) integration offer? 
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    The ELN integration enables real-time data capture from instruments, collaborative note-taking, linking data to samples and workflows, time tracking, and the ability to create reusable experiment templates. It also supports integration with ERP and CRM systems for enhanced connectivity across lab operations. 
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                      How does Zentixs Labs support compliance? 
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Zentixs Labs includes features for incident management, such as tracking Out-of-Specification (OOS), Out-of-Trend (OOT), and Out-of-Control (OOC) events with automated logging and root cause analysis tools. Additionally, it provides document control, secure audit trails, compliance reporting for global regulations (e.g., FDA, ISO, GDPR), and e-signature support.                     </div>
                  </div>
                </div>


              </div>
            </div>
            <div className='col-lg-6'>
              <div className='accordion faq-accordion' id='accordionExample2'>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-6'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-6'
                      aria-expanded='false'
                    >
                     What types of reports can Zentixs Labs generate? 
                    </button>
                  </h5>
                  <div
                    id='collapse-6'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-6'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Zentixs Labs offers customizable reporting tools, including data visualization, statistical analysis, compliance reports, and KPI dashboards. Users can create real-time sample status reports, inventory summaries, and project updates, which are accessible on desktop and mobile.                     </div>
                  </div>
                </div>


                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-7'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-7'
                      aria-expanded='false'
                    >
                      How is inventory managed in Zentixs Labs? 
                    </button>
                  </h5>
                  <div
                    id='collapse-7'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-7'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Inventory and reagent management in Zentixs Labs includes automated tracking with alerts for low stock levels and expiration dates, demand forecasting, hazardous material management, and streamlined reordering processes. Inventory can be monitored across multiple locations, with usage records and disposal tracking to ensure compliance.                     </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-8'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-8'
                      aria-expanded='false'
                    >
                     Does Zentixs Labs offer features for scientific data management? 
                    </button>
                  </h5>
                  <div
                    id='collapse-8'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-8'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, the Scientific Data Management System (SDMS) in Zentixs Labs enables automated data capture, parsing, centralization, and version control. It supports standardized data formats, metadata tagging, document management, and archiving for secure data storage and retrieval.                     </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-9'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-9'
                      aria-expanded='false'
                    >
                      Can Zentixs Labs track OOS, OOT, OOC, and incidents? 
                    </button>
                  </h5>
                  <div
                    id='collapse-9'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-9'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, Zentixs Labs includes a dedicated incident tracking module for managing OOS, OOT, and OOC events. The system allows for documentation of incidents, investigation workflows, root cause analysis, and CAPA (Corrective and Preventive Actions) tracking. Alerts notify users of incidents to ensure timely resolution.                     </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-10'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-10'
                      aria-expanded='false'
                    >
                      Is Zentixs Labs accessible remotely? 
                    </button>
                  </h5>
                  <div
                    id='collapse-10'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-10'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, Zentixs Labs supports cloud-based deployment, enabling remote access via desktop or mobile devices. It also includes multi-location support to connect lab activities across different sites, allowing for seamless data access and collaboration regardless of location.                     </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqLabs;
