import React from 'react';

const LimsContentThree = () => {
  return (
    <>
      <section className='ptb-120 dark-purple-bg'>
        <div className='container'>
          <div className="row justify-content-center text-center">
            <div className='col-md-10'>
              <h2 className='title2 pb-2 text-white'>Why Choose Zentixs LIMS?</h2>
              <p className='text-white'>Zentixs LIMS offers efficient, secure data management tailored to your lab’s workflow, <br /> with a user-friendly interface that enhances productivity and supports real-time decision-making.
              </p>
            </div>
          </div>
          <div className="row mt-5 justify-content-center">

            <div className="col-lg-6 col-md-6">
              <div className="crypto-timeline  mb-4 mb-lg-0">
                <div className="crypto-timeline-icon">
                <i class="fal fa-tools fa-2x"></i>
                </div>
                <h5 className=' text-warning mb-3'>Instrument Integration and Maintenance
                </h5>
                <p>
                  Zentixs Labs connects with laboratory instruments for automated data capture, scheduling maintenance and calibration, and tracking usage. This integration improves data accuracy and ensures regulatory compliance.              </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="crypto-timeline  mb-4 mb-lg-0">
                <div className="crypto-timeline-icon">
                <i class="fal fa-box fa-2x"></i>
                </div>
                <h5 className=' text-warning mb-3'>Inventory and Reagent Management</h5>
                <p>
                  The inventory module tracks stock levels, expiration dates, and hazardous materials, automating notifications for low stock and simplifying reordering processes. This feature helps prevent delays and supports compliance with inventory management standards.              </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="crypto-timeline  mb-4 mb-lg-0">
                <div className="crypto-timeline-icon">
                <i class="fal fa-database  fa-2x"></i>
                </div>
                <h5 className='mb-3 text-warning'>Scientific Data Management System (SDMS)
                </h5>
                <p>
                  The SDMS centralizes and standardizes data from various sources, supporting metadata tagging, document management, version control, and secure data archiving, ensuring data integrity and easy retrieval for audits or analysis.              </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="crypto-timeline  mb-4 mb-lg-0">
                <div className="crypto-timeline-icon">
                <i class="fal fa-file-check  fa-2x"></i>

                </div>
                <h5 className=' text-warning mb-3'>Compliance and Reporting</h5>
                <p>
                  Zentixs Labs provides tools for regulatory compliance, including incident tracking for OOS, OOT, and OOC events, audit trails, e-signature support, and customizable reporting for internal reviews or external regulatory inspections.              </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LimsContentThree;