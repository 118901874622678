import React from 'react';

const LimsContent = () => {
  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between justify-content-center'>
            <div className='col-lg-5 col-md-12'>
              <div className='feature-content-wrap'>
                <h2 className='title2'>End-to-End Sample Management</h2>
               <p className='mt-3 lead'>Zentixs Labs tracks samples from registration through testing, storage, and disposal. Features include barcode integration, chain-of-custody documentation, and workflow automation, ensuring sample traceability and efficient lab operations.</p> </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap'>
                <img
                  src='assets/img/lms1.jpg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LimsContent;
