import React, { useRef, useState, useEffect } from "react";
import { motion } from 'framer-motion'


const tabs = [
  "Sample Management", "ELN Integration", "Instrument Management", "Environment and Storage Management", "Inventory and Reagent Management",
  "Scientific Data Management System (SDMS)", "Project Management", "Reports and Compliance"

]


const LimsContentFour = () => {
  const [activeTab, setActiveTab] = useState(0)
  const tabsRef = useRef(null)
  const tabRefs = useRef([])
  const handleTabClick = (index, scroll = false) => {
    setActiveTab(index)
    if (scroll && tabsRef.current && tabRefs.current[index]) {
      const tabsRect = tabsRef.current.getBoundingClientRect()
      const tabRect = tabRefs.current[index].getBoundingClientRect()

      if (tabRect.right > tabsRect.right) {
        // If clicked tab is not fully visible on the right, scroll to make it visible
        tabsRef.current.scrollTo({
          left: tabsRef.current.scrollLeft + (tabRect.right - tabsRect.right) + 20, // 20px buffer
          behavior: 'smooth'
        })
      } else if (tabRect.left < tabsRect.left) {
        // If clicked tab is not fully visible on the left, scroll to make it visible
        tabsRef.current.scrollTo({
          left: tabsRef.current.scrollLeft - (tabsRect.left - tabRect.left) - 20, // 20px buffer
          behavior: 'smooth'
        })
      }
    }
  }

  useEffect(() => {
    tabRefs.current = tabRefs.current.slice(0, tabs.length)
  }, [])

  useEffect(() => {
    if (tabsRef.current && tabRefs.current[activeTab]) {
      const tabsRect = tabsRef.current.getBoundingClientRect()
      const tabRect = tabRefs.current[activeTab].getBoundingClientRect()

      if (tabRect.right > tabsRect.right || tabRect.left < tabsRect.left) {
        tabsRef.current.scrollTo({
          left: tabRect.left - tabsRect.left,
          behavior: 'smooth'
        })
      }
    }
  }, [activeTab])

  // Define unique JSX content for each tab
  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <div className="container">
            <div className="row justify-content-center align-items-center g-5">
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">End-to-End Sample Tracking</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Barcode integration and QR code support for precise sample tracking
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Detailed sample location tracking across storage units, freezers, and labs
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated sample logging upon receipt, with location and handler details
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Chain of Custody and Traceability</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Chain of custody tracking for full traceability of each sample’s history
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Time-stamped logs for sample transfers, ensuring accountability
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Digital signatures and e-signature support for secure handling records
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Workflow Management</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Lifecycle management for each sample, from receipt to disposal
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated labeling with customizable label templates
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Role-based task assignment and tracking for sample handling workflows
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Multi-Stage Approval Workflows</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Customizable approval stages for data validation and sample results review
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Role-based access to enable different approval tiers (e.g., technician, supervisor)
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated alerts for pending approvals, with escalation notifications for delays
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Advanced Sample Pooling and Aliquoting</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Support for sample pooling (combining samples) and aliquoting (dividing into smaller volumes)
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated tracking of sub-samples and derivative samples with parent-child traceability
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Chain of custody tracking for each aliquot to maintain traceability and compliance
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Customizable Sample Request Forms</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Built-in templates for sample request forms with configurable fields
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated assignment of requests to lab personnel based on availability or expertise
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time status tracking of sample requests, visible to requesters
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      case 1:
        return (
          <div className="container">
            <div className="row  align-items-center g-5">
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Data Integration and Connectivity</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Direct data import from instruments for immediate availability in the ELN
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Data synchronization across LIMS, ERP, and CRM systems
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>API support for seamless integration with external systems and software
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Real-Time Data Sharing and Collaboration </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time collaborative note-taking for team-based research documentation
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Tagging and linking of notes to specific samples, experiments, and workflows
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Data synchronization across locations for multi-site research projects
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Time Tracking and Activity Logging </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated time logging for sample processing and laboratory activities
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Task duration analysis for process optimization and benchmarking
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time resource allocation insights for staff and equipment efficiency
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Template and Protocol Library</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Pre-loaded templates and protocols for common laboratory experiments
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Ability for users to create and share custom templates and protocols
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Version control and audit trail for protocol modifications
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Lab Notebook Search and Filter Capabilities</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Advanced search functionality to locate specific experiments or notes
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Filtering options by date, project, researcher, and sample ID
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Quick linking to related samples, instruments, or experiments
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      case 2:
        return (
          <div className="container">
            <div className="row  align-items-center g-5">
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Instrument Integration and Data Transfer</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated data capture from instruments, minimizing manual errors
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time error notifications for equipment malfunctions or deviations
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Integration with IoT devices for continuous monitoring of instrument status
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Calibration and Maintenance Scheduling</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automatic alerts for upcoming calibration and maintenance tasks
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Calibration records for each instrument with full traceability of calibrations
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Logging of maintenance history to ensure consistent instrument performance
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Calibration and Maintenance Scheduling</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automatic alerts for upcoming calibration and maintenance tasks
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Calibration records for each instrument with full traceability of calibrations
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Logging of maintenance history to ensure consistent instrument performance
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Instrument Audit Trail</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Comprehensive audit trails for tracking instrument usage and data history
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>User and date-stamped records for each instrument interaction
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Compliance-ready audit trails for regulatory inspections
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Predictive Maintenance and Analytics </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Machine learning-based predictive maintenance alerts for equipment
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Analytics dashboard for instrument performance metrics and failure trends
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Integration with maintenance vendors for streamlined service scheduling
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Instrument Utilization Metrics </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Dashboard for tracking instrument utilization rates and identifying underused equipment
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated reports on peak usage times to optimize scheduling and prevent bottlenecks
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Notifications for overuse or misuse of critical instruments
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Incident Tracking and Deviation Management </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated identification and documentation of incidents related to OOS, OOT, and OOC events
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Root cause analysis tools for incidents, with configurable investigation workflows
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Comprehensive logging and documentation of corrective actions, including CAPA assignment and tracking
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Incident trend analysis for proactive identification of recurring issues
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Integrated alerts and notifications to ensure timely investigation and resolution of deviations
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      case 3:
        return (
          <div className="container">
            <div className="row  align-items-center g-5">
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Environmental Monitoring</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time tracking of environmental conditions (e.g., temperature, humidity)
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Integration with sensors for automated data logging and alerting
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Historical data storage for regulatory compliance with environmental standards
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Compliance with Storage and Handling Standards </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automatic alerts for deviations from storage temperature and humidity ranges
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Standardized protocols for sample storage in compliance with regulations
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time monitoring of freezer, fridge, and storage unit conditions
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Automated Compliance Checks for Storage Conditions </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Pre-set temperature, humidity, and pressure thresholds with automated alerts
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Scheduled compliance checks to ensure adherence to storage conditions
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Comprehensive logs for environmental compliance and historical condition data
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Real-Time Monitoring and Graphical Reporting </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Visualization tools for monitoring temperature and humidity fluctuations
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time dashboard for lab environmental conditions with color-coded alerts
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Graphical trend analysis for spotting patterns or issues over time
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        )
      case 4:
        return (
          <div className="container">
            <div className="row  align-items-center g-5">
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Automated Inventory Tracking</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time inventory updates with barcode and RFID tagging support
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automatic notifications for low stock levels or upcoming expirations
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Integration with procurement systems for efficient reordering
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Configurable Reagent Management</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Rules for safe reagent storage and handling per regulatory guidelines
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated disposal alerts for expired or obsolete reagents
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Inventory lifecycle management, from procurement to disposal
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Waste Management and Compliance </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Configurable workflows for waste handling and disposal tracking
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time tracking of hazardous waste quantities and disposal actions
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Compliance reports for waste management per regulatory requirements
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Real-Time Inventory Forecasting and Demand Planning </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Forecasting tools based on historical consumption and upcoming projects
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Integration with procurement systems to automate order placements
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Alerts for potential stock shortages to prevent workflow disruptions
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Hazardous Material Management </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Classification and handling guidelines for hazardous reagents
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Integration with safety documentation, including MSDS and handling procedures
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Tracking of disposal dates and procedures to ensure regulatory compliance
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      case 5:
        return (
          <div className="container">
            <div className="row  align-items-center g-5">
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Automated Data Capture and Parsing</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automatic data imports from lab instruments, minimizing data handling errors
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Parsing tools for raw data transformation into structured, analyzable formats
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Scheduled data capture for ongoing experiments and routine checks
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Data Centralization and Standardization </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Unified repository for diverse data sources, including instrument data and reports
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Data standardization with enforced naming conventions for consistency
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Metadata tagging for organized data retrieval and traceability
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Document and File Management </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Support for diverse document types, including images, reports, and spreadsheets
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Categorization and tagging for easy document retrieval
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Expiration tracking for regulated documents, with alerts for renewals
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Data Version Control and Audit Trails </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Version history tracking for all document updates and changes
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Timestamped audit trails for complete data traceability and compliance
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Access to historical versions for regulatory and audit requirements
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Dynamic Data Import Templates </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Configurable templates for data import from various instrument types
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Data mapping for consistent data transformation from raw inputs
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>User-defined data fields to adapt templates to specific experiments or protocols
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      case 6:
        return (
          <div className="container">
            <div className="row  align-items-center g-5">
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Resource Allocation and Task Management</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time resource tracking for personnel, equipment, and lab spaces
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Project timelines and Gantt chart features for task scheduling
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Cost tracking and budgeting tools to manage project expenses
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Client and Stakeholder Portal </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Secure client portal for accessing sample status, results, and reports
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Configurable access permissions for custom visibility and data sharing
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time updates and project progress reports for clients and stakeholders
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Data Archiving and Retrieval </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Long-term archival options for inactive data and closed projects
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Searchable archive with retrieval tools for historical data review
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated archiving for specific datasets based on retention policies
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      case 7:
        return (
          <div className="container">
            <div className="row  align-items-center g-5">
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Document Management for SOPs and Manuals</h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Document control with version tracking and e-signatures for approvals
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Secure role-based access for regulatory documents
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Version history and update notifications for compliance with documentation standards
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Advanced Analytics and Reporting </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Customizable data visualization and reporting tools for lab metrics
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time dashboards for sample processing, lab KPIs, and productivity
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Scheduled, automated reporting for internal reviews and regulatory audits
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Mobile and Cloud Accessibility </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Cloud-based access for remote data entry, tracking, and monitoring
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Mobile app support for sample logging, tracking, and task updates
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Multi-location connectivity to synchronize lab activities across sites
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Lab and Data Security </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Data encryption protocols for secure data storage and transmission
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Role-based access control for data protection and confidentiality
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Regular security audits and compliance checks to ensure data integrity
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="hd-feature-content text-center text-lg-start">
                  <h5 className="mb-3 clr-text">Global Compliance Management </h5>
                  <ul className="list-unstyled mb-0">
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Real-time compliance updates for evolving global standards
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Region-specific compliance reports (e.g., EU GDPR, FDA, ISO 15189)
                    </li>
                    <li className="py-1 d-flex">
                      <i className="fad fa-check-circle me-2 text-primary"></i>Automated documentation generation for international regulatory inspections
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )

    }
  }



  return (
    <>
      <section className='ptb-120'>
        <div className='container'>
          <div className="row justify-content-center text-center">
            <div className='col-md-10'>
              <h2 className='title2 pb-2'>Benefits of a modern, cloud-based LIMS</h2>
            </div>
          </div>

          <div className="d-flex align-items-center">
            {/* Desktop view */}
            <div className="d-flex overflow-auto pb-2" ref={tabsRef} style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
              {tabs.map((tab, index) => (
                <motion.button
                  key={tab}
                  ref={el => tabRefs.current[index] = el}
                  className={`btn  btn-sm rounded-pill ${activeTab === index ? 'btn-primary' : 'btn-ghost-primary'} me-2 flex-shrink-0`}
                  onClick={() => handleTabClick(index, true)}
                  whileTap={{ scale: 0.95 }}

                >
                  {tab}
                </motion.button>

              ))}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="p-4 border rounded"
            >
              {renderTabContent()}
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LimsContentFour;