import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FaqTwo = () => {
  return (
    <>
      <section className='faq-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-12 col-12'>
              <SectionTitle title='Frequently Asked Questions'  centerAlign/>              
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-6 custom-mb-mobile'>
              <div className='accordion faq-accordion' id='accordionExample'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-1'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-1'
                      aria-expanded='false'
                    >
                      What is an Electronic Logbook (ELN)?
                    </button>
                  </h5>
                  <div
                    id='collapse-1'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-1'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    An ELN is a digital platform for recording, managing, and storing data logs electronically, replacing traditional paper-based records.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      How does an ELN improve compliance?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    It automates record-keeping, enforces SOPs, and ensures data integrity, making regulatory compliance easier to maintain.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      Can the ELN integrate with other systems?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Yes, it can integrate with instruments, databases, and other enterprise systems for seamless data collection and workflow automation.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      Is an ELN secure?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    ELNs offer secure data storage with features like encryption, access controls, and audit trails to protect sensitive information.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                     Can multiple users collaborate on an ELN?
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Yes, ELNs support real-time collaboration, allowing multiple users to work together, track changes, and approve entries.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-6'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-6'
                      aria-expanded='false'
                    >
                     What industries use ELNs?
                    </button>
                  </h5>
                  <div
                    id='collapse-6'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-6'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    ELNs are commonly used in pharmaceuticals, manufacturing, biotech, and other regulated industries for tracking processes and ensuring compliance.
                   </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
            <div className='accordion faq-accordion' id='accordionExample2'>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-7'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-7'
                      aria-expanded='false'
                    >
                    Can the ELN track equipment usage?
                    </button>
                  </h5>
                  <div
                    id='collapse-7'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-7'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, ELNs can log and track equipment usage, calibration schedules, and maintenance activities for efficient asset management.
                   </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-8'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-8'
                      aria-expanded='false'
                    >
                      Does the ELN support mobile access?
                    </button>
                  </h5>
                  <div
                    id='collapse-8'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-8'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, many ELNs are accessible via mobile devices, allowing users to enter data or review logs remotely.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-9'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-9'
                      aria-expanded='false'
                    >
                      How is data validated in an ELN?
                    </button>
                  </h5>
                  <div
                    id='collapse-9'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-9'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    ELNs include validation rules and automatic checks to ensure that data entered is accurate, consistent, and compliant with predefined standards.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-10'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-10'
                      aria-expanded='false'
                    >
                      What happens if there's an error in the log?
                    </button>
                  </h5>
                  <div
                    id='collapse-10'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-10'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    ELNs allow users to correct errors with proper documentation, ensuring that any changes are traceable through audit trails.
                    </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-11'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-11'
                      aria-expanded='false'
                    >
                    Can the ELN generate reports?
                    </button>
                  </h5>
                  <div
                    id='collapse-11'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-11'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    Yes, ELNs can automatically generate customizable reports for audits, regulatory reviews, and internal performance monitoring.
                   </div>
                  </div>
                </div>

                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-12'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-12'
                      aria-expanded='false'
                    >
                      Is the ELN cloud-based or on-premise?
                    </button>
                  </h5>
                  <div
                    id='collapse-12'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-12'
                    data-bs-parent='#accordionExample2'
                  >
                    <div className='accordion-body'>
                    ELNs are commonly used in pharmaceuticals, manufacturing, biotech, and other regulated industries for tracking processes and ensuring compliance.
                   </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqTwo;
